// Dependency
import React, { useState } from "react";
import ReactPlayer from 'react-player'
import { graphql } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Img from "gatsby-image";

// Components
import Layout from "../../components/layouts";
import Hero from "../../components/hero/hero";
import CenteredContent from "../../components/centered-content";
import ProductCard from "../../components/cards/product-card";
import Stories from "../../components/stories";
import AmbassadorSwiper from "../../components/ambassadors/ambassador-swiper";
import BackgroundCards from "../../components/background-cards";
import Support from "../../components/support/support";
import Sticky from "../../components/sticky/sticky";
import StickyText from "../../components/sticky/sticky-text";
import FaqCard from "../../components/cards/faq-card";
import Accordion from "../../components/faq/accordion";
import InstaFeed from "../../components/instafeed/instafeed";
import FooterTrust from "../../components/trustpilot/footer-trustpilot";
import CompareTable from "../../components/compare-table/compare";

// Internationalization
import { useTranslation } from 'react-i18next';

// Assets
import videoBackground from "./../../images/compare/flow-video.png";

// Styles
import * as compareStyles from './../pages/compare.module.scss';
import * as shopStyles from "./../../templates/pages/shop.module.scss";
import * as stickyStyles from '../../components/sticky/sticky.module.scss';
import ProductList from "../product/hooks/product-list";
import ComparePage from "../../components/compare-table/compare";

// GraphQL to get Dato data
export const query = graphql`
  query  ($slug: String!, $locale: String!) {
    datoCmsHeader{
      saleLabelColor{
        hex
      }
      saleLabelBackground{
        hex
      }
    }
    allDatoCmsComparePage(
      filter: {seoSlug: { eq: $slug },locale: {eq: $locale}}
    ){
    nodes {
        locale
        seoSlug
        title
        heroContent
        desktopHero{
            fluid(maxWidth: 1500){
            ...GatsbyDatoCmsFluid
          }
        }
        mobileHero{
            fluid {
            ...GatsbyDatoCmsFluid
          }
        }
    desktopVideo{
      url
    }
    mobileVideo{
      url
    }
    centeredContent
    products{
      centraId
      slug
      featuredImage{
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      newPrice
      locale
      title
      parentProductName
      trustpilotId
      saleBadge
      bestseller
      batteryText
      batteryTime
      batteryType
      performance
      beatsPerMinute
      loadTime
      strokeLength
      motor
      noiseLevel
      maximumPressure
      specifications
      weight
      size
      dimensions
    }
    scrollGrids{
      text
      images{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      textToRight
    }
    faqText
    faqCards{
      title
      description
      image{
         fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      blogLink{
        seoSlug
        categories{
          slug
        }
      }
    }
    seoMetaTags {
    tags
    id
    }
      }
    }
}
`


// Template
const Compare = (props) => {
  const pageData = props.data.allDatoCmsComparePage.nodes;

  const mediaData = {
    videoSrcURL: pageData && pageData[0].desktopVideo ? pageData[0].desktopVideo.url : '',
    videoSrcURLMobile: pageData && pageData[0].mobileVideo ? pageData[0].mobileVideo.url : '',
  }
  const sources = [
    pageData && pageData[0].mobileHero && pageData[0].mobileHero.fluid,
    {
      ...pageData && pageData[0].mobileHero && pageData[0].desktopHero.fluid,
      media: `(min-width: 900px)`,
    },
  ]

  const [productList, setProductList] = useState([]);

  // Translation Function
  const { t } = useTranslation();

  const headerData = props.data.datoCmsHeader;


  return (

    <Layout seoData={pageData[0].seoMetaTags} country={props.pageContext.country} location={props.location}>
      <ProductList createProductList={setProductList} />
      {
        sources[0] &&
        <Hero
          cname={"cd-hero-small-text"}
          sources={sources}
          mediaData={mediaData}
        >
          { //  is visible only if data exist
            pageData && pageData[0].heroContent && <div
              dangerouslySetInnerHTML={{ __html: pageData[0].heroContent }}>
            </div>
          }

        </Hero>
      }
      <CenteredContent>
        { // MediaBanner is visible only if data exist
          pageData && pageData[0].centeredContent && <div
            dangerouslySetInnerHTML={{ __html: pageData[0].centeredContent }}>
          </div>
        }

        {
          pageData && pageData[0].products[0].beatsPerMinute ? <AnchorLink offset="20" href="#compare">{t('compare.anchorLink')}</AnchorLink> : ''
        }

      </CenteredContent>

      <div className={shopStyles.cdShopWrapper}>
        <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
          {pageData && pageData[0].products && pageData[0].products.map((product, index) => {
            let prodFilter = productList.filter((prod) => {
              return product.centraId.toString() === prod.centraProduct;
            })

            let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
            return (
              <div key={index} className={shopStyles.cdShopProductCard}>
                <ProductCard labelBackground={headerData.saleLabelBackground.hex} color={headerData.saleLabelColor.hex} centraProd={centraProd} data={product} placement="Compare Page" position={index + 1} />
              </div>
            )
          })}
        </div>
      </div>

      {pageData && pageData[0].scrollGrids && pageData[0].scrollGrids.map((scrollGrid, index) => {
        return (
          <Sticky key={index} cname={scrollGrid && scrollGrid.textToRight ? stickyStyles.cdStickyGridReverse : ''} >
            <StickyText>
              <div dangerouslySetInnerHTML={{ __html: scrollGrid.text }}></div>
            </StickyText>
            <div className={stickyStyles.cdStickyGridRight}>
              {scrollGrid.images.map((stickyImage, index) => {
                return (
                  <div key={index.toString()} className={`${stickyStyles.cdStickyGridImg}`}>
                    <Img alt="" fluid={stickyImage.fluid} />
                  </div>
                )
              })}
            </div>
          </Sticky>
        )
      })}

      <Sticky>
        <StickyText>
          {
            pageData && pageData[0].faqText && <div dangerouslySetInnerHTML={{ __html: pageData[0].faqText }}></div>
          }
        </StickyText>
        <div className={stickyStyles.cdStickyGridRight}>
          {pageData && pageData[0].faqCards && pageData[0].faqCards.map((faqCard, index) => {
            return (
              <FaqCard data={faqCard} key={index.toString()} />
            )
          })}
        </div>
      </Sticky>

      {
        pageData && pageData[0].products[0].beatsPerMinute ? <ComparePage products={pageData[0].products} centraProducts={productList} /> : ''
      }


      {
        pageData && pageData[0].backgroundCards && <BackgroundCards country={props.pageContext.country} data={pageData[0].backgroundCards} />
      }

    </Layout>
  )
}


export default Compare;